/* eslint-disable @typescript-eslint/no-explicit-any */
import { Alert, Box, Button, Modal, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { App, UID, UserState } from "../../models/shared_models";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  initialState,
  updateAppState,
  updateUserState,
} from "../../app/states/appState";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { auth, globalFuncs, logAnalytics } from "../../services/web.service";
import { Link } from "../../stylevars";
import CancelIcon from "@mui/icons-material/Cancel";
import styled from "styled-components";
import { resetChatId } from "../../services/util";
import { GoogleLogin } from "./GoogleLogin";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "300px",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const CloseBtn = styled.div`
  float: right;
`;

enum LoginPage {
  SIGNUP = "signup",
  LOGIN = "login",
  RESET = "reset",
}

/// FORM validation for signup //  for signup
const validationSchema = Yup.object({
  email: Yup.string().email().required(),
  password: Yup.string().required().min(6),
  retrypassword: Yup.string()
    .required()
    .min(6)
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

// For logi form
const validationSchema2 = Yup.object({
  email: Yup.string().email().required(),
  password: Yup.string().required().min(6),
});

declare const fbq: any;

interface Props {
  LoginBoxOpen?: boolean;
}

export default function LoginBox({ LoginBoxOpen = false }: Props) {
  // for login box
  //this function returns the JSX.
  const appState: App = useSelector((state: any) => state.appState.app as App);
  const [page, setPage] = React.useState<LoginPage>(
    appState.loggedIn ? LoginPage.LOGIN : LoginPage.SIGNUP
  );
  const [alertMsg, setAlertMsg] = React.useState<string>("");
  const [sucessMsg, setSucessMsg] = React.useState<string>("");
  const dispatch = useDispatch();
  const userState: UserState = useSelector(
    (state: any) => state.appState.userState as UserState
  );
  const [open, setOpen] = React.useState<boolean>(
    appState.loggedIn && !appState.emailVerified
  );

  const [resetEmail, setResetEmail] = React.useState<string>("");

  useEffect(() => {
    //mount
    globalFuncs.on("LOGIN", openLogin);
    return () => {
      //on umnount
    };
  }, []);

  useEffect(() => {
    //mount
    setOpen(appState.loggedIn && !appState.emailVerified);
    return () => {
      //on umnount
    };
  }, [appState.loggedIn, appState.emailVerified]);

  const openLogin = (open: boolean) => {
    if (open) logAnalytics("login_box");
    setOpen(open);
  };

  // reest passsword
  function resetPassword(e: any) {
    e.preventDefault();
    sendPasswordResetEmail(auth, resetEmail)
      .then(() => {
        // Password reset email sent!
        // ..
        setSucessMsg("Password reset email has been sent to your email");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ..
      });
  }

  // Signup form //
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      retrypassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      //alert(JSON.stringify(values, null, 2));
      globalFuncs.emit("LOGIN", true);
      setAlertMsg("");
      dispatch(updateAppState({ loading: true }));
      createUserWithEmailAndPassword(auth, values.email, values.password)
        .then(async (userCredential) => {
          // user has signed up
          const user = userCredential.user;
          //user.email
          //user.uid
          console.log("USER CREDENTIAL CREATED:", userCredential);

          // Setup Intial UserData.
          const newUserState: UserState = {
            chatId: userState.chatId,
            email: user.email || "",
            uid: user.uid as UID,
            model: userState.model,
          };

          dispatch(updateUserState(newUserState));

          // record FB track.
          logAnalytics("sign_up");
          fbq("track", "Lead");

          /// Send a system message
          /* await sendSystemMessage(userState.chatId, "User has signed up! Thank the user for subscribing and continue the conversation").then((x: { data: ChatBotDBColumns[] }) => {
            // refresh chat , chat room will auto refresh if user login
            dispatch(updateAppState({ signedUp: true }))
          }).catch(x => {
            console.log("ERROR",)
            // setShowIntro(true)
          }) */

          emailSentVerfication();
          ///  navigate("/signup");

          // ...
        })
        .catch(() => {
          setAlertMsg("Failed to Signup. Account might already exist.");
          globalFuncs.emit("LOGIN", false);
          // ..
        })
        .finally(() => {
          dispatch(updateAppState({ loading: false }));
        });
    },
  });

  // FOr LOgin
  const formik2 = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema2,
    onSubmit: (values) => {
      //alert(JSON.stringify(values, null, 2));
      console.log("Execute Login");
      globalFuncs.emit("LOGIN", true);
      setAlertMsg("");
      dispatch(updateAppState({ loading: true }));
      signInWithEmailAndPassword(auth, values.email, values.password)
        .then(() => {
          // Signed in
          // const user = userCredential.user;
          // ...
          //dispatch(updateAppState({ loading:false}))
        })
        .catch(() => {
          globalFuncs.emit("LOGIN", false);
          setAlertMsg("Failed to Login.");
        })
        .finally(() => {
          dispatch(updateAppState({ loading: false }));
        });
    },
  });

  const handleClose = () => {
    //globalFuncs.emit("LOGIN", false)
  };

  const emailSentVerfication = () => {
    ///email sent.
    if (!auth?.currentUser) return null;
    globalFuncs.emit("LOGIN", true);
    sendEmailVerification(auth?.currentUser)
      .then(() => {
        // Email verification sent!
        // ...
        globalFuncs.emit("LOGIN", false);
        setSucessMsg(
          "Registration Successful! Check Your Email For the Confirmation Email"
        );
        logAnalytics("sign_up");
      })
      .catch((error) => {
        setAlertMsg(
          "Failed to send confirmation Email. Please contact contact@artius.studio for help."
        );
        globalFuncs.emit("LOGIN", false);
      });
  };

  const logOut = () => {
    globalFuncs.emit("LOGIN", true);
    auth.signOut().then(
      function () {
        // Sign-out successful.
        globalFuncs.emit("LOGIN", false);
        dispatch(
          updateUserState({ ...initialState.userState, chatId: resetChatId() })
        );

        //navigate("/");
      },
      function () {
        // An error happened.
        globalFuncs.emit("LOGIN", false);
      }
    );
  };

  return (
    <Modal
      open={open}
      // onClose={handleClose}
      onClose={handleClose}
    >
      <Box sx={style}>
        <div className="loginBox" aria-label="loginBox">
          {(appState.emailVerified && appState.loggedIn) ||
            (!appState.loggedIn && (
              <CloseBtn
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CancelIcon></CancelIcon>
              </CloseBtn>
            ))}
          <div className="headerBreacdrumb center">
            <Link
              onClick={() => setPage(LoginPage.SIGNUP)}
              active={page != LoginPage.SIGNUP}
            >
              Signup
            </Link>{" "}
            |
            <Link
              data-testid="login-section"
              active={page != LoginPage.LOGIN}
              onClick={() => setPage(LoginPage.LOGIN)}
            >
              Login
            </Link>
            {appState.loggedIn && (
              <>
                |{" "}
                <Link data-testid="logout" active={true} onClick={logOut}>
                  Logout
                </Link>
              </>
            )}
          </div>

          {sucessMsg && (
            <Alert data-testid="error-msg" severity="success">
              {sucessMsg}
            </Alert>
          )}
          {alertMsg && !sucessMsg && (
            <Alert data-testid="error-msg" severity="error">
              {alertMsg}
            </Alert>
          )}
          {!appState.emailVerified &&
            appState.loggedIn &&
            !alertMsg &&
            !sucessMsg && (
              <Alert severity="error" data-testid="error-msg">
                Email not verified. Please check your inbox or Spam folder.{" "}
                <Link active={false} onClick={emailSentVerfication}>
                  Resend Email
                </Link>
              </Alert>
            )}

          {page == LoginPage.SIGNUP && !appState.loggedIn && (
            <form onSubmit={formik.handleSubmit} data-testid="Signup-page">
              <p>
                Ready to embark on a transformative journey with Lotus?
                Subscribe now and experience the future of mental health
                support.
              </p>

              <GoogleLogin />
              <TextField
                inputProps={{ "data-testid": "email", autoComplete: "email" }}
                fullWidth
                label="Email"
                id="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <p></p>
              <TextField
                inputProps={{
                  "data-testid": "password1",
                  autoComplete: "new-password",
                }}
                type="password"
                fullWidth
                label="Password"
                id="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
              <p></p>
              <TextField
                inputProps={{
                  "data-testid": "password2",
                  autoComplete: "new-password",
                }}
                type="password"
                fullWidth
                label="Retry Password"
                id="retrypassword"
                value={formik.values.retrypassword}
                onChange={formik.handleChange}
                error={
                  formik.touched.retrypassword &&
                  Boolean(formik.errors.retrypassword)
                }
                helperText={
                  formik.touched.retrypassword && formik.errors.retrypassword
                }
              />

              <p className="elemntCenter">
                <Button
                  data-testid="signup-btn"
                  type="submit"
                  className="orangeButton"
                  variant="contained"
                >
                  Sign Up
                </Button>
              </p>
              <span className="elemntCenter">
                <div>
                  {" "}
                  By Signing Up You agree to our{" "}
                  <Link
                    active={false}
                    onClick={() => {
                      window.open(
                        "https://blog.lotustherapist.com/terms-conditions/"
                      );
                    }}
                  >
                    {" "}
                    Terms And Conditions
                  </Link>
                  <p></p>
                  Your{" "}
                  <Link
                    active={false}
                    onClick={() => {
                      window.open(
                        "https://blog.lotustherapist.com/your-confidentiality/"
                      );
                    }}
                  >
                    Privacy
                  </Link>{" "}
                  is out top most priority
                </div>
              </span>
            </form>
          )}

          {page == LoginPage.LOGIN && !appState.loggedIn && (
            <form onSubmit={formik2.handleSubmit} data-testid="Login-page">
              <GoogleLogin label="Sign In With Google" />
              <p></p>
              <TextField
                inputProps={{
                  "data-testid": "login-email",
                  autoComplete: "email",
                }}
                fullWidth
                label="Email"
                id="email"
                value={formik2.values.email}
                onChange={formik2.handleChange}
                error={formik2.touched.email && Boolean(formik2.errors.email)}
                helperText={formik2.touched.email && formik2.errors.email}
              />
              <p></p>
              <TextField
                inputProps={{
                  "data-testid": "login-password",
                  autoComplete: "current-password",
                }}
                type="password"
                fullWidth
                label="Password"
                id="password"
                value={formik2.values.password}
                onChange={formik2.handleChange}
                error={
                  formik2.touched.password && Boolean(formik2.errors.password)
                }
                helperText={formik2.touched.password && formik2.errors.password}
              />
              <p></p>

              <p className="elemntCenter">
                <Button
                  type="submit"
                  data-testid="login-btn"
                  className="orangeButton"
                  variant="contained"
                >
                  Login
                </Button>
              </p>
              <p>
                <Link onClick={() => setPage(LoginPage.RESET)} active={false}>
                  I forgot my Password
                </Link>
              </p>
            </form>
          )}

          {page == LoginPage.RESET && !appState.loggedIn && (
            <form onSubmit={resetPassword} data-testid="Login-page">
              Reset Your Password
              <p></p>
              <TextField
                inputProps={{
                  "data-testid": "login-email",
                  autoComplete: "email",
                }}
                fullWidth
                label="Email"
                id="email"
                value={resetEmail}
                onChange={(x) => setResetEmail(x.target.value)}
              />
              <p></p>
              <p className="elemntCenter">
                <Button
                  type="submit"
                  data-testid="login-btn"
                  className="orangeButton"
                  variant="contained"
                >
                  Reset Password
                </Button>
              </p>
            </form>
          )}
        </div>
      </Box>
    </Modal>
  );
}
