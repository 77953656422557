/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import styled from "styled-components";
import { datadogRum } from "@datadog/browser-rum";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./main-page.scss";
import {
  Box,
  CircularProgress,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Modal,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Capacitor } from "@capacitor/core";

import {
  initialState,
  updateAppState,
  updateUserState,
} from "../../app/states/appState";
import { useEffect, useMemo } from "react";
import { App, RoleTypes, UserState } from "../../models/shared_models";
import MenuIcon from "@mui/icons-material/Menu";
import { CenterContainer, stylevars } from "../../stylevars";

import logoImg from "../../assets/logo_white.png";
import { onAuthStateChanged } from "firebase/auth";
import {
  CreateUpdateUserState,
  analytics,
  auth,
  createSummary,
  functions,
  getUserRoles,
  getUserState,
  globalFuncs,
  logAnalytics,
  requestNotificationPermission,
} from "../../services/web.service";
import useDoOnce from "../../services/hooks/useDoOnce";
import LoginBox from "../../components/LoginBox/LoginBox";
import { resetChatId } from "../../services/util";
import Notepad from "../NotePad/NotePad";
import GlobalRoute from "../../services/hooks/globalRoute";
import CheckList from "../../components/CheckList";
import BillingPage from "../billing/billing.component";
import { httpsCallable } from "firebase/functions";
import Exception from "../billing/exception.component";
import { setUserId } from "firebase/analytics";
const ChatRoom = React.lazy(() => import("../ChatRoom/ChatRoom"));
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const fbq: any;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "300px",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

// Component CSS
const AppContainer = styled.div`
  background-color: white;
  border-radius: 20px;
  max-width: 25rem;
  margin: 1rem;
  padding: 1rem;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
  width: 100%;

  @media only screen and (max-device-width: ${stylevars["screen-mobile"]}) {
    border-radius: 0px;
    margin: 0px;
    box-shadow: none;
  }
`;

const ChatLoader = styled.div`
  display: flex;
  width: 100%;
  flex-flow: column;
  justify-items: center;
  align-items: center;
  gap: 2rem;
  padding: 1rem;
  box-sizing: border-box;
`;

const Centered = styled(CenterContainer)``;

const Logo = styled.img`
  width: 3rem;
  float: right;
  cursor: pointer;
`;

export const Footer = styled.div`
  font-size: ${stylevars["font-size-05x"]};
  font-weight: ${stylevars["font-regular"]};
  text-align: center;
`;
/* 
  const StyledAlert = styled(Alert)`
    position: absolute;
  ` */

function MainPage() {
  const appState: App = useSelector((state: any) => state.appState.app as App);
  const userState: UserState = useSelector(
    (state: any) => state.appState.userState as UserState
  );
  const [openGoals, setOpenGoals] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const dispatch = useDispatch();
  //const navigate = useNavigate();

  const doOnce = useDoOnce();
  globalFuncs.on("GOALS", setOpenGoals);
  // triggered on page load
  useEffect(() => {
    // check if user is logged in
    //if (!auth) {
    const unsub = onAuthStateChanged(
      auth,
      async (user: any) => {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const config = params.get("utm_id");

        if (user) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/firebase.User
          const uid = user.uid;
          dispatch(updateAppState({ loading: false }));
          // const userRole = (jwt_decode(user.accessToken) as any).stripeRole || RoleTypes.FREE
          // get user roles
          const userRole = (await getUserRoles(uid)) as RoleTypes;
          console.log(userRole);

          // get user state.
          await getUserState(uid);
          setUserId(analytics, uid);

          // do tracking for new signups and purchases.
          if (user.emailVerified && userRole == RoleTypes.FREE) {
            if (doOnce("join_group")) {
              logAnalytics("join_group");
              fbq("track", "CompleteRegistration");
            }
          } else if (user.emailVerified && userRole != RoleTypes.FREE) {
            if (doOnce("purchase")) {
              logAnalytics("purchase");
              fbq("track", "Purchase", { value: 12.99, currency: "USD" });
            }
          }
          /*if (doOnce('login')) {
            logEvent(analytics, 'login');
            fbq('track', 'Lead');
          } */
          console.log("Logged In", user, userRole);
          dispatch(
            updateAppState({
              emailVerified: user.emailVerified,
              loggedIn: true,
              email: user.email,
              uid: uid,
              role: userRole,
              loginCheck: true,
            })
          );
          logAnalytics("login_success");

          datadogRum.setUser({
            id: uid,
            email: user?.email,
            plan: userRole,
          });
          datadogRum.setUserProperty("email", user?.email);
          datadogRum.setUserProperty("plan", userRole);
          datadogRum.setUserProperty("id", uid);
          // ...
          // if (!userState.notificationId) {
          // if no notfication we will request one
          // console.log("REQUEST", userState.notificationId);
          //requestNotificationPermission();
          //  }
        } else {
          // User is signed out
          // ...
          console.log("Logged Out");

          //reset app state //resetChatId
          dispatch(updateAppState({ ...initialState.app, loginCheck: true }));
          //dispatch(updateUserState({...initialState.userState, chatId:resetChatId()}))
          //show login box if this is email notification referal.
          if (config == "emailNotification") globalFuncs.emit("LOGIN", true);
        }
      },
      (error: any) => {
        console.error("AUTH ERROR", error);
      }
    );

    return () => {
      // Anything in here is fired on component unmount.
      unsub();
    };
  }, []);

  // trigger when alert message is dsplayed.
  useEffect(() => {
    if (appState.errorMsg) {
      setTimeout(() => {
        dispatch(updateAppState({ errorMsg: "" }));
      }, 5000);
    }

    return () => {
      // Anything in here is fired on component unmount.
    };
  }, [appState.errorMsg, dispatch]);

  // autosave whenever store is updated
  useEffect(() => {
    console.log("SAVE USER STATE:", !!userState.uid, userState);
    if (userState.uid) CreateUpdateUserState(userState.uid, userState);

    // log the model
    datadogRum.setUserProperty("model", userState.model);
    logAnalytics("ab_group", { testgroup: userState.model });

    return () => {
      // Anything in here is fired on component unmount.
    };
  }, [userState]);

  const disableNotification = (disable: boolean) => {
    // disable the notifications
    dispatch(updateUserState({ disableNotification: disable }));
  };

  const clearAllChat = () => {
    ///clear all chat
    if (!appState.loggedIn) {
      dispatch(updateUserState({ chatId: resetChatId() }));
      return null;
    }
    dispatch(updateAppState({ loading: true }));
    createSummary(userState.chatId)
      .then((x) => {
        ///summarize
        console.log("Summary", x);
      })
      .finally(() => {
        //
        dispatch(updateAppState({ loading: false }));
        dispatch(updateUserState({ chatId: resetChatId() }));
        handleClose();
      });
  };

  async function openUserSubPortal() {
    if (Capacitor.isNativePlatform()) {
      //if this is native we redirect the page instead.
      window.location.assign("https://lotustherapist.com/");
      return null;
    }

    // helo
    dispatch(updateAppState({ loading: true }));
    const functionRef = httpsCallable(
      functions,
      "ext-firestore-stripe-payments-createPortalLink"
    );
    const { data } = await functionRef({
      returnUrl: window.location.origin,
      locale: "auto", // Optional, defaults to "auto"
      // configuration: "bpc_1JSEAKHYgolSBA358VNoc2Hs", // Optional ID of a portal configuration: https://stripe.com/docs/api/customer_portal/configuration
    });
    window.location.assign((data as any).url);
  }

  const logOut = () => {
    dispatch(updateAppState({ loading: true }));
    auth.signOut().then(
      function () {
        // Sign-out successful.
        dispatch(updateAppState({ loading: false }));
        dispatch(
          updateUserState({ ...initialState.userState, chatId: resetChatId() })
        );

        //navigate("/");
      },
      function () {
        // An error happened.
        dispatch(updateAppState({ loading: false }));
      }
    );
  };

  /* 
    const ref = useCickOutside<HTMLImageElement>(() => {
      //on click outside
      console.log("On click outside")
    }) */
  const returnRoutes = useMemo(() => {
    return (
      <React.Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route
            path="/"
            element={
              <GlobalRoute>
                {!appState.loginCheck && (
                  <ChatLoader>
                    <CircularProgress color="success" />
                    Logging in..
                  </ChatLoader>
                )}
                {appState.loginCheck && <ChatRoom />}
              </GlobalRoute>
            }
          />
          <Route
            path="/notepad"
            element={
              <GlobalRoute>
                <Notepad />
              </GlobalRoute>
            }
          />
          <Route
            path="/billing"
            element={
              <GlobalRoute>
                <BillingPage />
              </GlobalRoute>
            }
          />
          <Route
            path="/exception"
            element={
              <GlobalRoute>
                <Exception />
              </GlobalRoute>
            }
          />
        </Routes>
      </React.Suspense>
    );
  }, [userState.chatId, appState.route, appState.loginCheck]);
  //

  return (
    <BrowserRouter>
      <Centered>
        <AppContainer>
          {!appState.loading || (
            <LinearProgress aria-label="loader" color="success" />
          )}

          <LoginBox></LoginBox>

          <Modal
            open={openGoals}
            onClose={() => {
              setOpenGoals(false);
            }}
          >
            <Box sx={style}>
              {userState?.goalChecklist && (
                <CheckList
                  showDelete={true}
                  goalChecklist={userState?.goalChecklist}
                ></CheckList>
              )}
              {!userState?.goalChecklist && (
                <div>
                  You have no goals, ask Lotus for a list of objectives you can
                  work on.
                </div>
              )}
            </Box>
          </Modal>

          <IconButton
            onMouseDown={(evt) => setAnchorEl(evt.currentTarget)}
            aria-label="menu"
          >
            <MenuIcon />
          </IconButton>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onMouseDown={clearAllChat}>Clear Chat</MenuItem>
            {appState.loggedIn && (
              <MenuItem
                onMouseDown={() => {
                  dispatch(updateAppState({ route: "/notepad" }));
                  handleClose();
                }}
              >
                My Journal
              </MenuItem>
            )}

            {appState.loggedIn && !userState?.disableNotification && (
              <MenuItem
                onMouseDown={() => {
                  disableNotification(true);
                }}
              >
                Disable Notifications
              </MenuItem>
            )}
            {appState.loggedIn && userState?.disableNotification && (
              <MenuItem
                onMouseDown={() => {
                  disableNotification(false);
                }}
              >
                Enable Notifications
              </MenuItem>
            )}
            {!appState.loggedIn && (
              <MenuItem
                aria-label="login-btn"
                onMouseDown={() => {
                  globalFuncs.emit("LOGIN", true);
                  handleClose();
                }}
              >
                Sign Up / Login
              </MenuItem>
            )}
            {appState.loggedIn && (
              <MenuItem
                onMouseDown={() => {
                  logOut();
                }}
              >
                Logout
              </MenuItem>
            )}
            {appState.role == RoleTypes.BASIC && (
              <MenuItem
                onMouseDown={() => {
                  window.location.href =
                    "mailto:contact@lotustherapist.com?subject=I%20Would%20like%20to%20unsubscribe";
                  handleClose();
                }}
              >
                My Billing
              </MenuItem>
            )}

            <MenuItem
              onMouseDown={() => {
                window.open(
                  "https://blog.lotustherapist.com/about-us/",
                  "_blank"
                );
                handleClose();
              }}
            >
              About Us
            </MenuItem>
            <MenuItem
              onMouseDown={() => {
                window.open(
                  "https://blog.lotustherapist.com/your-confidentiality/",
                  "_blank"
                );
                handleClose();
              }}
            >
              Privacy Policy
            </MenuItem>
            <MenuItem
              onMouseDown={() => {
                window.open("https://blog.lotustherapist.com/", "_blank");
                handleClose();
              }}
            >
              Blog
            </MenuItem>

            <MenuItem
              onMouseDown={() => {
                window.open("https://lotustherapist.com/", "_blank");
                handleClose();
              }}
            >
              My Chat
            </MenuItem>
          </Menu>

          <Logo
            src={logoImg}
            onMouseDown={() => {
              dispatch(updateAppState({ route: "/" }));
              handleClose();
            }}
          ></Logo>
          <p></p>

          {returnRoutes}

          {/*  {appState.errorMsg && <StyledAlert onClose={() => { dispatch(updateAppState({ errorMsg: "" })) }} color='error' sx={{ color: "red" }} className="errorMsg" severity="error">{appState.errorMsg}</StyledAlert>}
           */}
        </AppContainer>
      </Centered>
      <Footer>
        <a href="https://blog.lotustherapist.com/about-us/">About Us</a> |{" "}
        <a href="https://blog.lotustherapist.com/terms-conditions/">
          Terms & Conditions
        </a>{" "}
        |{" "}
        <a href="https://blog.lotustherapist.com/your-confidentiality/">
          Your Privacy
        </a>{" "}
        | v0.7.3b
      </Footer>
    </BrowserRouter>
  );
}
export default MainPage;
