import React, { Fragment, useState } from "react";
import { App, CBTGoal, GoalChecklist } from "../models/shared_models";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { updateUserState } from "../app/states/appState";
import styled from "styled-components";
import { globalFuncs } from "../services/web.service";


interface Props{
    goalChecklist : GoalChecklist;
    showDelete ? : boolean;
}

const LotusButton = styled(Button)`
    margin-right: 1rem !important;
`

export default function CheckList(props: Props) {
    //check list function.
    const appState: App = useSelector((state: any) => state.appState.app as App);

    const [checkList, setCheckList] = useState<CBTGoal[]>(props?.goalChecklist?.goals)
    const dispatch = useDispatch()

    const saveGoals = ()=>{
        // save goals.

        if(appState.loggedIn){
            const goals : GoalChecklist = {
                goals:checkList,
                objective: props?.goalChecklist?.objective
            }
            dispatch(updateUserState({goalChecklist:goals}))
        }else{
            globalFuncs.emit("LOGIN", true);
        }
            
    }

    const deleteGoals = ()=>{
        // save goals.
        dispatch(updateUserState({goalChecklist:null}))
        
    }

    return (
        <Fragment>
            <div>{props?.goalChecklist?.objective}</div>
            <ol>
                {checkList?.map((x,indx) => <li key={x.index}>{x.description}</li>)}
            </ol>
            <LotusButton onMouseDown={saveGoals} type="submit" className="orangeButton" variant="contained">Save Goals</LotusButton>
            
            {props.showDelete && <LotusButton onMouseDown={deleteGoals} type="submit" className="orangeButton" variant="contained">Delete Goals</LotusButton>}

        </Fragment>)
}