import { Interface } from "readline";

// Helper for generating Opaque types.
type Opaque<T, K> = T & { __opaque__: K };

//Important Custom types.
export type JobId = Opaque<string, "JobId">;
export type ProjectId = Opaque<string, "ProjectId">;
export type UID = Opaque<string, "UID">;

// App state
export interface AppState {
  app: App;
  userState: UserState;
}

export interface App {
  loading: boolean;
  emailVerified: boolean;
  loggedIn: boolean;
  email: string;
  uid: UID;
  role: RoleTypes | null;
  errorMsg: string;
  requests: number;
  signedUp: boolean;
  route: string | null;
  loginCheck: boolean;
}

export enum RoleTypes {
  FREE = "free",
  BASIC = "basic",
  PRO = "pro",
}

export interface UserState {
  chatId: string;
  email: string | null;
  uid: UID | null;
  notificationId?: string;
  disableNotification?: boolean;
  goalChecklist?: GoalChecklist | null;
  model: string;
}

export interface GoalChecklist {
  objective: string;
  goals: CBTGoal[];
}

export interface CBTGoal {
  index: string;
  description: string;
  completed: boolean;
}

export interface GetChatRequest {
  senderId: string;
}

export interface ChatBotDBColumns {
  id: number;
  modifiedOn: string;
  sender: string;
  receiver: string;
  message: string;
  appName: string;
  metadata: string;
  subject: string;
}

export enum OPENAI_ROLES {
  SYSTEM = "system",
  USER = "user",
  ASSISTANT = "assistant",
}

export interface SendToLotus {
  senderId: string;
  message: string;
  subject?: string;
  model: string;
}

export interface LotusMessageReply {
  message: ChatBotDBColumns[];
  functions: any;
  singleMessage: string;
}

export interface Notes {
  text: string;
}

export enum DbName {
  project = "project",
  user = "user",
  notes = "notes",
  inpainting = "inpainting",
  subscription = "subscriptions",
}
