import React, { useEffect } from "react";
import { CenterContainerCol, IntroText } from "../ChatRoom/ChatRoom.styles";
import girl from "../../assets/serverfire.png";
import { CenterContainer, Link } from "../../stylevars";
import { Button, CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { collection, doc, onSnapshot, setDoc } from "firebase/firestore";
import { App, DbName, RoleTypes } from "../../models/shared_models";
import { analytics, db, logAnalytics } from "../../services/web.service";
import { updateAppState } from "../../app/states/appState";
import styled from "styled-components";
import { Footer } from "../mainPage/main-page";
import { useNavigate } from "react-router-dom";

export default function BillingPage() {
  const appState: App = useSelector((state: any) => state.appState.app as App);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  console.log("ROLE:", appState.role);
  // if (appState.role !== RoleTypes.FREE) navigate("/");
  useEffect(() => {
    //
    if (appState.role == RoleTypes.BASIC || appState.role == RoleTypes.PRO) {
      navigate("/");
    }
    logAnalytics("billing");
  }, []);

  const startSub = async (prcing: string) => {
    dispatch(updateAppState({ loading: true }));
    logAnalytics("start_checkout");
    const newCityRef = doc(
      collection(db, DbName.user, appState.uid, "checkout_sessions")
    );
    const docRef = await setDoc(newCityRef, {
      price: prcing,
      success_url: window.location.origin,
      cancel_url: window.location.origin,
    });

    const unsub = onSnapshot(newCityRef, (snap) => {
      const { error, url } = snap.data() as any;
      if (error) {
        // Show an error to your customer and
        // inspect your Cloud Function logs in the Firebase console.
        alert(`An error occured: ${error.message}`);
        dispatch(updateAppState({ loading: false }));
      }
      if (url) {
        logAnalytics("add_to_cart");
        // We have a Stripe Checkout URL, let's redirect.
        window.location.assign(url);
        dispatch(updateAppState({ loading: false }));
      }
    });
  };

  return (
    <CenterContainerColScroll>
      <img src={girl} width="100%" />
      <IntroText>
        We are At Maximum Capacity right Now 😢
        <p></p>
        You can try again later
        <br />
        or
        <p></p>
        {!appState.loading || (
          <>
            This Will Take a Minute...
            <br />
            <CircularProgress />
          </>
        )}
        {!appState.loading && (
          <Button
            disabled={appState.role == RoleTypes.BASIC}
            onClick={(x) => startSub("price_1Ns3MJLRxwZO1XbmvgaWrsag")}
            type="submit"
            className="orangeButton"
            variant="contained"
          >
            Signup For Lotus Premium ($14.99)
          </Button>
        )}
        <p></p>
        ✨ Safeguard your mental health with uninterrupted access to Lotus.
        <br />
        <p></p>
        <Footer>
          {/**<Link onClick={() => navigate("/exception")} active={false}>
            Alternatively You can Request for an Exception
        </Link>**/}
        </Footer>
      </IntroText>
    </CenterContainerColScroll>
  );
}

export const CenterContainerColScroll = styled(CenterContainer)`
  flex-direction: column;
  height: 90%;
  overflow: auto;
`;
