import styled from "styled-components";
import React from "react";
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { auth, logAnalytics } from "../../services/web.service";

const LoginProvider = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  border: 1px solid lightgrey;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
`;
const provider = new GoogleAuthProvider();

export function GoogleLogin({
  label = "Sign Up With Google",
}: {
  label?: string;
}) {
  const onSignIn = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        logAnalytics("google_signin");
      })
      .catch((error) => {
        // Handle Errors here.
        console.log(error);
      });
  };

  return (
    <LoginProvider onClick={onSignIn}>
      <img src="/google.svg" width="20"></img>
      <span>{label}</span>
    </LoginProvider>
  );
}
