import styled from "styled-components";
export const stylevars = {
  "screen-mobile": "600px",

  "light-green": "#80CEC8",
  "white-green": "#E3F7F5",
  "grey-border": "#A4A4A4",
  "green-bubble": "#51BF83",
  //e5ece9

  "font-size-05x": "0.8rem",
  "font-size-1x": "1rem",
  "font-size-1-5x": "1.25rem",
  "font-size-2x": "1.5rem",
  "font-thin": 200,
  "font-regular": 400,
  "font-bold": 700,
  "padding-1x": "1rem",
  "padding-05x": "0.5rem",
  "margin-1x": "1rem",
  "margin-2x": "2rem",
  "margin-3x": "3rem",
  "margin-05x": "0.5rem",
};

export const CenterContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const Link = styled.span<{ active: boolean }>`
  cursor: pointer;
  ${(props) =>
    !props.active
      ? // styling when link is  active
        "color: green;"
      : // style for non active link
        `color: black; 
text-decoration: underline;`}

  &.small {
    color: grey;
    font-size: 0.8rem;
    text-decoration: underline;
  }
`;
