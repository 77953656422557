import React, { useEffect } from "react";
import { CenterContainerCol, IntroText } from "../ChatRoom/ChatRoom.styles";
import girl from "../../assets/logo_white.png";
import { CenterContainer, Link } from "../../stylevars";
import { useDispatch, useSelector } from "react-redux";
import { collection, doc, onSnapshot, setDoc } from "firebase/firestore";
import { App, DbName } from "../../models/shared_models";
import { analytics, db, logAnalytics } from "../../services/web.service";
import { updateAppState } from "../../app/states/appState";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

export default function Exception() {
  const appState: App = useSelector((state: any) => state.appState.app as App);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    //
    logAnalytics("billing");
  }, []);

  const startSub = async (prcing: string) => {
    dispatch(updateAppState({ loading: true }));
    const newCityRef = doc(
      collection(db, DbName.user, appState.uid, "checkout_sessions")
    );
    logAnalytics("add_to_cart");
    const docRef = await setDoc(newCityRef, {
      price: prcing,
      success_url: window.location.origin,
      cancel_url: window.location.origin,
    });

    const unsub = onSnapshot(newCityRef, (snap) => {
      const { error, url } = snap.data() as any;
      if (error) {
        // Show an error to your customer and
        // inspect your Cloud Function logs in the Firebase console.
        alert(`An error occured: ${error.message}`);
        dispatch(updateAppState({ loading: false }));
      }
      if (url) {
        // We have a Stripe Checkout URL, let's redirect.
        window.location.assign(url);
        dispatch(updateAppState({ loading: false }));
      }
    });
  };

  return (
    <CenterContainerColScroll>
      <IntroText>
        <img src={girl} width="30%" />
        <h1>Get Lotus Premium for Free</h1>
        At Lotus, we{"'"}re committed to supporting those in financial need.{" "}
        <br />
        If youve benefited from Lotus and are facing financial constraints,
        reach out to us.
        <br />
        We{"'"}re here to offer free Lotus Premium Accounts on a case-by-case
        basis, ensuring everyone has access to the help they deserve.
        <br />
        <Link active={false}>
          <a href="mailto:contact@lotustherapist.com">
            contact@lotustherapist.com
          </a>
        </Link>
      </IntroText>
    </CenterContainerColScroll>
  );
}

export const CenterContainerColScroll = styled(CenterContainer)`
  flex-direction: column;
  height: 90%;
  overflow: auto;
`;
