import { Button } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { DbName, Notes, UserState } from "../../models/shared_models";
import { CreateUpdateNotes, db } from "../../services/web.service";
import { updateAppState } from "../../app/states/appState";
import { doc, getDoc } from "firebase/firestore";

const NotepadInput = styled.textarea`
    width: 100%;
    height: calc(100% - 10rem);
`

const NotePadForm = styled.form`
    height: 100%;
    width: 100%;
`

export default function Notepad() {
    const textRef = useRef<HTMLTextAreaElement>(null)
    const userState: UserState = useSelector((state: any) => state.appState.userState as UserState);
    const dispatch = useDispatch()

    useEffect(() => {
        //on mount
        getNotes();
        return () => {
            //unmount
        }
    }, [userState.uid])


    const getNotes = async () => {
        //get notes
        if (textRef?.current && userState?.uid) {
            dispatch(updateAppState({ loading: true }))
            const docRef = doc(db, DbName.user, userState?.uid, DbName.notes, userState?.uid);
            const docSnap = await getDoc(docRef);
            dispatch(updateAppState({ loading: false }))
            if (docSnap.exists()) {
                console.log("Get Notes data:", docSnap.data());
                const data = docSnap.data() as Notes
                textRef.current.value = data?.text
                return data;
            } else {
                // doc.data() will be undefined in this case
                return null;
            }
        }
    }

    const saveNotes = (e:any) => {
        //save notes.
        if (textRef?.current?.value && userState?.uid) {
            const body: Notes = {
                text: textRef?.current?.value
            }
            dispatch(updateAppState({ loading: true }))
            CreateUpdateNotes(userState?.uid, body).finally(()=>{
                dispatch(updateAppState({ loading: false }))
            })
        }
        e?.preventDefault();
    }

    return (
        <NotePadForm onSubmit={(e) => saveNotes(e)}>
            <NotepadInput ref={textRef} rows={10} placeholder="Write anything here" ></NotepadInput>
            <Button onMouseDown={(e) => saveNotes(e)} type="submit" className="orangeButton" variant="contained">Save Notes</Button>
        </NotePadForm>
    )
}