import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppState, UID, UserState } from "../../models/shared_models";
import { getUseId } from "../../services/util";

// Define a type for the slice state

// Define the initial state using that type
export const initialState: AppState = {
  app: {
    loading: false,
    errorMsg: "",
    emailVerified: false,
    loggedIn: false,
    email: "",
    role: null,
    uid: "" as UID,
    requests: 0,
    signedUp: false,
    route: null,
    loginCheck: false,
  },
  userState: {
    chatId: getUseId(),
    email: null,
    uid: null,
    model: ["agentProfile", "agentProfile2", "agentProfile3"]?.[
      Math.floor(Math.random() * 3)
    ],
  },
};

//    model: ["gpt-3.5-turbo", "gpt-4o"]?.[Math.round(Math.random() * 1)],

export const appSlice = createSlice({
  name: "appState",
  initialState: initialState,
  reducers: {
    updateAppState: (state: AppState, action: PayloadAction<any>) => {
      state.app = { ...state.app, ...action.payload };
    },

    updateUserState: (state: AppState, action: PayloadAction<any>) => {
      state.userState = { ...state.userState, ...action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateAppState, updateUserState } = appSlice.actions;

export default appSlice.reducer;

// Other code such as selectors can use the imported `RootState` type
export const getAppState = (state: AppState) => state.app;
