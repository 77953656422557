// to genrate a random hash, useful to create documents and collection ids.
export function makeid(length: number): string {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

// this function gets and creates users ID in user storage
//chat sessoons are not longer peristant.
export function getUseId() {
  return makeid(8);
  // get user id from storgae or else create a new one.
  /*   let data = localStorage.getItem("chatId");
  if (data) {
    // if data exist we will return false
    return data;
  } else {
    data = makeid(8);
    localStorage.setItem("chatId", data);
    return data;
  } */
}

//reset the chat id
export function resetChatId() {
  return makeid(8);
  /* const data = makeid(8);
  localStorage.setItem("chatId", data);
  return data; */
}
