import React from 'react';
import './App.css';
import MainPage from './pages/mainPage/main-page'
import { Provider, } from 'react-redux';
import store from './app/store';


function App() {

  return (
    <Provider store={store}>
    <div className="App">
      <MainPage></MainPage>
    </div>
    </Provider>
  );
}

export default App;
