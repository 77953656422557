import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { App } from "../../models/shared_models";
import { useNavigate } from "react-router-dom";

export default function GlobalRoute({children} : any){
    const appState: App = useSelector((state: any) => state.appState.app as App);
    const navigate = useNavigate();
    
    useEffect(()=>{
        //mount
        if(appState.route) navigate(appState.route)
        return ()=>{
            //unamount
        }
    },[appState.route])

    return(<Fragment>{children}</Fragment>)
}