// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  doc,
  setDoc,
  collection,
  query,
  where,
  orderBy,
  getDocs,
  getDoc,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import {
  connectFunctionsEmulator,
  getFunctions,
  httpsCallable,
} from "firebase/functions";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getPerformance } from "firebase/performance";
import {
  DbName,
  GetChatRequest,
  Notes,
  RoleTypes,
  SendToLotus,
  UID,
  UserState,
} from "../models/shared_models";
import store from "../app/store";
import { updateAppState, updateUserState } from "../app/states/appState";
import { getMessaging, getToken, isSupported } from "firebase/messaging";
import { datadogRum } from "@datadog/browser-rum";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCtOfpKM4LnV__YWyHcXc7hvni0oEVI-WU",
  authDomain: "lotus-d6cbb.firebaseapp.com",
  projectId: "lotus-d6cbb",
  storageBucket: "lotus-d6cbb.appspot.com",
  messagingSenderId: "918201711623",
  appId: "1:918201711623:web:db29514781bb5608a83ea2",
  measurementId: "G-WSKJZ54G3T",
};

datadogRum.init({
  applicationId: "28c787e2-3e8e-4860-93b5-56053a5081dd",
  clientToken: "pub2db9039d1da87d61a01ef212ffd6b44a",
  site: "us5.datadoghq.com",
  service: "lotus",
  env: "prod",
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 70,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "allow",
});

declare const fbq: any;
// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const functions = getFunctions(app, "us-central1");
const perf = getPerformance(app);
// Initialize Firebase Cloud Messaging and get a reference to the service
export const messaging: any | null = getFCMMessaging();

export let isLocal = false;
runFunctions();

async function getFCMMessaging() {
  return (await isSupported()) ? getMessaging(app) : null;
}

//pub sub for canvas
export const globalFuncs = {
  events: new Map(),
  on(eventType: string, fn: (...args: any[]) => void) {
    // add events to the event bus if it does not exist.
    if (!this.events.has(eventType)) this.events.set(eventType, fn);
    return this;
  },
  emit(eventType: string, ...args: any[]) {
    //trigger stored function
    if (this.events.has(eventType)) this.events.get(eventType)(...args);
    return this;
  },
};

function runFunctions() {
  if (process.env.NODE_ENV !== "production") {
    console.log("Local Host Detected");
    connectFunctionsEmulator(functions, "127.0.0.1", 5001);
    isLocal = true;
  }
}

//log anlaytics
export function logAnalytics(
  eventName: string,
  params?: {
    group?: string;
    message?: string;
    model?: string;
    testgroup?: string;
  }
) {
  logEvent(analytics, eventName, params);
  fbq("track", eventName);
  //log datadog action
  datadogRum.addAction(eventName, params);
  console.log("Log Analytics", eventName, params);
}
//Get notification token,
export async function getNotificationToken(): Promise<string | null> {
  // create or save token.
  return getToken(messaging, {
    vapidKey:
      "BIqz8nXWqq-VffvFooEMzaKWc9rrCLwTcfBK_leruKPlXms2Vgw2RdKuT3hAbI2f53ua_ml3w9LtHbxpQV0bdEU",
  })
    .then((currentToken) => {
      if (currentToken) {
        // Send the token to your server and update the UI if necessary
        // ...
        console.log("Token recieved:", currentToken);
        store.dispatch(updateUserState({ notificationId: currentToken }));
        return currentToken;
      } else {
        // no token found.
        // Show permission request UI
        console.log(
          "No registration token available. Request permission to generate one."
        );
        // ...
        return null;
      }
    })
    .catch((err) => {
      console.error("An error occurred while retrieving token. ", err);
      // ...
      return null;
    });
}

export function requestNotificationPermission() {
  console.log("Requesting permission...");
  Notification.requestPermission()
    .then((permission) => {
      console.log("PERMISSION", permission);
      if (permission === "granted") {
        console.log("Notification permission granted.");
        logAnalytics("notification");
        getNotificationToken();
      }
    })
    .catch((err) => {
      //if error
      console.error("An error occurred while requesting token. ", err);
    });
}

// get User Roles
export async function getUserRoles(uid: string): Promise<RoleTypes> {
  const docRef = collection(db, DbName.user, uid, DbName.subscription);
  const q = query(docRef, where("status", "==", "active"));
  const tmpFolder: Array<any> = [];
  const roles = await getDocs(q).then((querySnapshot) => {
    querySnapshot.forEach((doc) => {
      tmpFolder.push(doc.data() as any);
    });

    const activeSub = tmpFolder.filter((x) => x.status == "active");

    if (tmpFolder.length > 0 && activeSub.length > 0) {
      // if there is role data
      try {
        return activeSub[0].items[0].price.product.metadata.firebaseRole;
      } catch (e) {
        return RoleTypes.BASIC;
      }
    } else {
      return RoleTypes.FREE;
    }
  });
  return roles;
}

// create or update user state
//on sucess we will update the store.
export function CreateUpdateUserState(uid: UID, userState: UserState) {
  return setDoc(doc(db, DbName.user, uid), userState).then(() => {
    //update store when sucess
    /// store.dispatch(updateUserState(userState))
  });
}

export function CreateUpdateNotes(uid: UID, notes: Notes) {
  return setDoc(doc(db, DbName.user, uid, DbName.notes, uid), notes);
}

export async function getUserState(uid: UID) {
  // get user state.
  const docRef = doc(db, DbName.user, uid);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    console.log("User Data:", docSnap.data());
    const state: UserState = docSnap.data() as UserState;
    store.dispatch(updateUserState(state));
  }
}

// get the chat history
export function getChatData(senderId: string): Promise<any> {
  // get chat history

  const addMessage = httpsCallable(functions, "getChatData");
  const requestType: GetChatRequest = {
    senderId,
  };
  return addMessage(requestType as GetChatRequest);
}

// du summary
export function createSummary(subject: string): Promise<any> {
  // get chat history
  const addMessage = httpsCallable(functions, "summariseChat");
  const requestType = {
    subject,
  };
  return addMessage(requestType);
}

// send to locus
export function sendToLotus(
  senderId: string,
  message: string,
  model: string
): Promise<any> {
  // send a message to lotus
  const addMessage = httpsCallable(functions, "lotusResponse");
  const requestType: SendToLotus = {
    senderId,
    message,
    model,
  };
  return addMessage(requestType as SendToLotus);
}

// send to lotus as system message
export function sendSystemMessage(
  senderId: string,
  message: string,
  model: string
): Promise<any> {
  // send a message to lotus
  const addMessage = httpsCallable(functions, "sendSystemMessage");
  const requestType: SendToLotus = {
    senderId,
    message,
    model,
  };
  return addMessage(requestType as SendToLotus);
}
