import styled from "styled-components";
import { CenterContainer } from '../../stylevars'

export const CenterContainerCol = styled(CenterContainer)`
  flex-direction: column;


`

export const IntroText = styled.p`
  text-align: center;
`